export class Header {
    constructor() {

        const subNav = document.querySelector('.sub-nav')

        if (subNav) {
            window.addEventListener('scroll', function () {
                setTimeout(function () {
                    if (window.scrollY >= 30) {
                        if (!subNav.classList.contains('hidden')) {
                            subNav.classList.add('hidden')

                        }
                    } else {
                        if (subNav.classList.contains('hidden')) {
                            subNav.classList.remove('hidden')

                        }
                    }
                }, 500)
            })
        }
    }
}
