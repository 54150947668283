import Swiper, {Navigation, Thumbs} from "swiper";

export class Gallery {
    constructor()
    {
        const swiper_gallery_class = ".swiper_gallery"
        const swiper_gallery_thumbs_class = ".swiper_gallery_thumbs"

        const swiper_gallery = document.querySelector(swiper_gallery_class)
        const swiper_gallery_thumbs = document.querySelector(swiper_gallery_thumbs_class)


        if (swiper_gallery && swiper_gallery_thumbs) {
            this.init(swiper_gallery_class, swiper_gallery_thumbs_class)
        }
    }

    init(swiper1, swiper2)
    {

        let swiper = new Swiper(swiper2, {
            spaceBetween: 10,
            slidesPerView: 2,
            freeMode: true,
            watchSlidesProgress: true,
            breakpoints: {
                400: {
                    slidesPerView: 4,
                }
            }
        })

        let swiper_thumbs = new Swiper(swiper1, {
            modules: [Thumbs, Navigation],
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: swiper,
            },
        })
    }
}
