export class MobileHeader {
    constructor() {
        this.itemsMobile = document.querySelectorAll('.mobile .menu-item-has-children')

        if (this.itemsMobile) {
            this.init()
        }
    }

    init() {
        const items = this.itemsMobile

        items.forEach(function (el) {

            const link = el.querySelector(':scope > a')

            link.addEventListener('click', (e) => {
                if(el.classList.contains('active')) {
                    e.preventDefault()
                    el.querySelector('.sub-menu').classList.remove('active')
                    el.classList.remove('active')
                } else {
                    e.preventDefault()
                    el.querySelector('.sub-menu').classList.add('active')
                    el.classList.add('active')
                }
            })

        })
    }
}
