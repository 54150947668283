import '@css/main.scss';
import {Slider} from './components/Slider'
import {AnimatedCount} from './components/AnimatedCount'
import {Header} from './components/Header'
import {AnchorButton} from './components/AnchorButton'
import {Breadcrumb} from './components/Breadcrumb'
import {Gallery} from './components/Gallery'
import {Select} from './components/Select'
import {Menu} from './components/Menu'
import {MobileHeader} from './components/MobileHeader'
import AOS from 'aos/dist/aos.js'


window.onload = () => {
  new Slider()
  new AnimatedCount()
  new Header()
  new AnchorButton()
  new Breadcrumb()
  new Gallery()
  new Select()
  new Menu()
  new MobileHeader()

  AOS.init();
}
