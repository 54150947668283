import CountUp from "countup";

export class AnimatedCount {

    constructor()
    {
        this.options = {
            root: null,
            threshold: 0.25,
            rootMargin: '-100px',
        };
        this.elements = document.querySelectorAll('[animated_count]')
        this.toggled = {}
        this.elements.forEach((element) => {
            let temp_id = this.generateTempID()
            element.setAttribute('temp_id', temp_id)
            this.toggled[temp_id] = false
        })
        this.firstTriggers = this.elements.length
        this.registerObservers()
    }

    generateTempID()
    {
        let fourChars = () => {
            return (((1 + Math.random()) * 0x10000)|0).toString(16).substring(1).toUpperCase();
        }
        return (fourChars() + fourChars() + '-' + fourChars() + '-' + fourChars() + '-' + fourChars() + '-' + fourChars() + fourChars() + fourChars());
    }

    getTempID(element)
    {
        return element.getAttribute('temp_id')
    }

    removeTempID(element)
    {
        element.removeAttribute('temp_id')
    }

    registerObservers()
    {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (this.firstTriggers !== 0) {
                    this.firstTriggers -= 1
                    return;
                }
                if (!this.toggled[this.getTempID(entry.target)]) {
                    this.animate(entry.target)
                    this.toggled[this.getTempID(entry.target)] = true
                }
            })
        }, self.options)

        this.elements.forEach((element) => {
            observer.observe(element);
        })
    }

    animate(element)
    {
        console.log(element.innerText)
        this.removeTempID(element)
        let countUpNumber = new CountUp(element, 0, parseInt(element.innerText), 0, 4, null);
        if (!countUpNumber.error) {
            countUpNumber.start();
        }
    }

}



// class CountUp {
//     constructor(triggerEl, counterEl) {
//         const counter = document.querySelector(counterEl)
//         const trigger = document.querySelector(triggerEl)
//         let num = 0
//         const decimals = counter.dataset.decimals
//
//         const countUp = () => {
//             if (num < counter.dataset.stop)
//
//                 // Do we want decimals?
//                 if (decimals) {
//                     num += 0.01
//                     counter.textContent = new Intl.NumberFormat('en-GB', {
//                         minimumFractionDigits: 2,
//                         maximumFractionDigits: 2
//                     }).format(num)
//                 }
//                 else {
//                     // No decimals
//                     num++
//                     counter.textContent = num
//                 }
//         }
//
//         const observer = new IntersectionObserver((el) => {
//             if (el[0].isIntersecting) {
//                 const interval = setInterval(() => {
//                     (num < counter.dataset.stop) ? countUp() : clearInterval(interval)
//                 }, counter.dataset.speed)
//             }
//         }, { threshold: [0] })
//
//         observer.observe(trigger)
//     }
// }
//
// // Initialize any number of counters:
// new CountUp('#start1', '#counter1')
// new CountUp('#start2', '#counter2')
