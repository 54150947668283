export class Select {
    constructor() {

        const select = document.getElementById('select_reference')
        const references = document.querySelectorAll('.reference-table_item')

        if (select) {
            this.init(select, references)
        }
    }

    init(select, references) {
        let hash = window.location.hash.replace('#', '')
        let elToScroll = document.getElementById(hash)
        console.log(elToScroll)

        if (hash) {
            references.forEach(function (el) {
                el.classList.remove('active')
                if (hash === el.getAttribute('data-reference')) {
                    el.classList.add('active')
                }
            })
            setTimeout(function () {
                elToScroll.scrollIntoView()
            }, 200)
        }


        select.addEventListener('change', function (e) {
            references.forEach(function (el) {
                el.classList.remove('active')
                if (e.target.value === el.getAttribute('data-reference')) {
                    el.classList.add('active')
                }
            })

            window.history.replaceState(null, null, "#" + e.target.value);

        })
    }
}
