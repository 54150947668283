export class Breadcrumb {
    constructor() {
        const crumbs = document.getElementById('crumbs')

        if (crumbs) {
            const li = crumbs.querySelectorAll('li')

            li.forEach(function (e) {
                if (e.innerText) {
                    if(e.querySelector('a')){
                        e.classList.add('active')
                    }
                }
            })
        }
    }
}
