import Swiper, {Navigation, Pagination} from "swiper";

export class Slider {
    constructor()
    {
        const sliders = document.querySelectorAll('.swiper_classic')
        const productSlider = document.querySelectorAll('.swiper_productpromotion')
        const actusSlider = document.querySelectorAll('.swiper_actus')
        const catsSlider = document.querySelectorAll('.swiper_sub-cat')
        const productsDomainSlider = document.querySelectorAll('.productsDomain_swiper .swiper')

        if (sliders.length > 0) {
            sliders.forEach(function (e) {
                let el = ".swiper_classic_" + e.getAttribute('data-slug')


                new Swiper(el, {
                    modules: [Navigation, Pagination],
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 30,
                    navigation: {
                        nextEl: ".customer-next",
                        prevEl: ".customer-prev"
                    },
                    breakpoints: {
                        400:{
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                        },
                        1000: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                        },
                        1510: {
                            slidesPerView: 5,
                            slidesPerGroup: 5,
                        },
                    }
                })
            })
        }

        if (productSlider.length > 0) {
            productSlider.forEach(function () {
                let el = ".swiper_productpromotion";

                new Swiper(el, {
                    modules: [Navigation, Pagination],
                    slidePerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                    navigation: {
                        nextEl: ".productpromotion-next",
                        prevEl: ".productpromotion-prev"
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    }
                })
            })
        }

        if (actusSlider.length > 0) {
            actusSlider.forEach(function () {
                let el = ".swiper_actus";

                new Swiper(el, {
                    modules: [Navigation, Pagination],
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 38,
                    navigation: {
                        nextEl: ".actus-next",
                        prevEl: ".actus-prev"
                    },
                    pagination: {
                        el: ".swiper-pagination_actu",
                        clickable: true
                    },
                    breakpoints: {
                        800: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                            spaceBetween: 38,
                        },
                        1400: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 38,
                        }
                    }
                })
            })
        }

        if (catsSlider.length > 0) {
            catsSlider.forEach(function (e) {
                let el = ".swiper_sub-cat_" + e.getAttribute('data-cat')
                let navPrev = ".realisation-prev_" + e.getAttribute('data-cat')
                let navNext = ".realisation-next_" + e.getAttribute('data-cat')

                new Swiper(el, {
                    modules: [Navigation],
                    spaceBetween: 20,
                    navigation: {
                        nextEl: navNext,
                        prevEl: navPrev
                    },
                })
            })
        }

        if (productsDomainSlider.length > 0) {
            productsDomainSlider.forEach(function () {
                let el = ".productsDomain_swiper .swiper"

                new Swiper(el, {
                    modules: [Pagination, Navigation],
                    spaceBetween: 36,
                    slidesPerGroup: 1,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: ".productdomain-next",
                        prevEl: ".productdomain-prev"
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    breakpoints: {
                        500: {
                            slidesPerGroup: 2,
                            slidesPerView: 2,
                        },
                        780: {
                            slidesPerGroup: 3,
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerGroup: 4,
                            slidesPerView: 4,
                        }
                    }
                })
            })
        }
    }
}
