export class AnchorButton {
    constructor() {
        const anchorBtn = document.querySelectorAll('.filter-item')
        let anchor = null

        if (anchorBtn.length > 0) {

            this.init();

            anchorBtn.forEach(function (e) {
                e.addEventListener('click', function (el) {
                    setTimeout(function () {
                        anchor = window.location.hash.replace('#', '')
                        anchorBtn.forEach(function (e) {
                            e.classList.remove('active')
                            if (anchor === e.getAttribute('data-filter')) {
                                e.classList.add('active')
                            }
                        })
                    }, 100)

                })
            })
        }
    }

    init() {

        const anchorBtn = document.querySelectorAll('.filter-item')
        let anchor = window.location.hash.replace('#', '')

        if (anchorBtn.length > 0) {
            if (anchor !== "") {
                anchorBtn.forEach(function (e) {
                    e.classList.remove('active')

                    if (e.getAttribute('data-filter') === anchor) {
                        e.classList.add('active')
                        setTimeout(function () {
                            let domainElement = document.getElementById(e.getAttribute('data-filter'))
                            domainElement.scrollIntoView()
                        }, 100)
                    }
                })

            }
        }
    }
}
